import isbot from 'isbot';
import { loadCMP, parseQuery, inIFrame,rgpdActive,uspActive,isInViewport, isConnectedTV, displayFormatLinks, loadId5, isTouchDevice } from './tools';

import {banner_atf_bottom_over} from "./bannerATFBottomOver.js";
import {banner_atf_bottom_over_on_scroll_hide} from "./bannerATFBottomOverOnScrollHide";
import {banner_atf_bottom_over_on_scroll_show} from "./bannerATFBottomOverOnScrollShow";
import {banner_atf_top_over} from "./bannerATFTopOver";
import {banner_atf_top_push} from  "./bannerATFTopPush";
import { banner_atf_bottom_over_fixed } from './bannerATFBottomOverFixed.js';
import { corner_display } from "./cornerDisplay.js";
import {fixed_bloc} from "./fixedBloc";
import {passback_atf_side_left_over_fixed_on_scroll} from "./passbackATFSideLeftOverFixedOnScroll";
import {passback_atf_side_right_over_fixed_on_scroll} from "./passbackATFSideRightOverFixedOnScroll";
import { first } from 'lodash';
import { instream_video_tamildhool } from './instreamVideoTamildhool';

(function(window) {
    var tagParams = [];

    //Define window variable level (if we are in iframe)
    var windowTop;
    var documentTop;
    var isRefresh = false;
    
    if(inIFrame()){
        windowTop = parent.window;
        documentTop = parent.document;
    }
    else{
        windowTop = window;
        documentTop = document;
    }
    
    loadId5(documentTop);

    //Div animations must be call one time, this variable check if a div animation is load at the page level
    windowTop.animationLoad = []; 

    
        if(!window.d2pLoaded){ //Check if we are load in the local level (page or iframe)
            window.d2pLoaded = true;
            let readyStateOk = setInterval(function () {
                if (document.readyState === 'ready' || document.readyState === 'complete') {
                    let prebidScript = loadPrebid(); //Load Prebid

                    prebidScript.addEventListener("load",function(){
                        loadTags(); //Find tags in the pages et get her params
                    });

                    clearInterval(readyStateOk);
                }
            }, 100);
        }

        function loadTags(){
            let tags = getTags();
            getNextParam(tags);
        }

        function getNextParam(tags){
            if(tags && tags.length > 0){
                let tag = tags.pop();

                let tagId = getTagId(tag); //Id du tag qui nous permettra de récupérer les paramètres du tag pour l'enchère Prebid

                windowTop.animationLoad[tagId] = false;

                let runTagRequest = new XMLHttpRequest(); //Requête de récupération des paramètres sur notre API
                
                runTagRequest.onreadystatechange = function(){
                    if(runTagRequest.readyState == XMLHttpRequest.DONE){
                        if(runTagRequest.status == 200 || runTagRequest.status == 201){
                            if(runTagRequest.responseText != ""){
                                tagParams.push(JSON.parse(runTagRequest.responseText));
                                getNextParam(tags);
                            }
                        }
                        else if (runTagRequest.status == 400) {
                            console.log('There was an error 400');
                            getNextParam(tags);
                        }
                        else {
                            if (runTagRequest.status != 200 && runTagRequest.status != 201 && runTagRequest.status != 0) {
                                console.log('something else other than 200 was returned: ' + runTagRequest.status + " - " + runTagRequest.responseText);
                                getNextParam(tags);
                            }
                        }
                    }
                }

                runTagRequest.open("GET", DEV2PUB_API_URL + "/api/public/Dev2Pub/" + tagId, true);
                runTagRequest.send();
            }
            else{
                if(isbot(navigator.userAgent) == false){ 
                    launchNextTags();
                }
            }
        }

        function launchNextTags(){
            let paramsToLaunch = [];
            for(let param of tagParams){
                if(param.directTag){
                    loadDirectTag(param);
                    tagParams = tagParams.filter(function(value){
                        return value.id != param.id;
                    });
                }else{
                    if(param.theMoneytizerAdUnit){
                        let addParamToLaunch = true;
                        for(let paramToLaunch of paramsToLaunch){
                            if(paramToLaunch.theMoneytizerAdUnit){
                                if(paramToLaunch.id == param.id){
                                    addParamToLaunch = false;
                                    break;
                                }
                            }
                        }
    
                        if(addParamToLaunch){
                            paramsToLaunch.push(param);
                            tagParams = tagParams.filter(function(value){
                                return value.id != param.id;
                            });
                        }
                    }
                }
            }

            if(paramsToLaunch){
                let adUnits = [];
                let paramsLaunched = [];
                for(let param of paramsToLaunch){
                     //CMP integration if not exist
                     if(param && param.cmp){
                        if(!windowTop.d2pCmpInit){
                            windowTop.d2pCmpInit = true;
                            loadCMP();
                        }
                    }

                    if(param && param.idTheMoneytizerFormatName){
                        loadTheMoneytizerTag(param);
                    }
                    else{
                        if(param && (param.theMoneytizerAdUnit || param.adUnit)){
                            if(param && param.theMoneytizerAdUnit){
                                let divId = param.id;
                
                                if (inIFrame()) {
                                    if (!window.frameElement.id) {
                                        window.frameElement.id = param.id;
                                    }
                                    divId = window.frameElement.id;
                                }
        
                                let tag = document.getElementById(param.id + "script");

                                if(param.demoMode){
                                    if(param.formatJsName == "banner_atf_bottom_over_on_scroll_hide"){
                                        if(isTouchDevice()){
                                            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/320x100.jpg"></div>';
                                        }
                                        else{
                                            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/728x90.jpg"></div>';
                                        }
                                    }
                                    else if(param.formatJsName == "banner_atf_top_over"){
                                        if(isTouchDevice()){
                                            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/320x100.jpg"></div>';
                                        }
                                        else{
                                            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/970x250.jpg"></div>';
                                        }
                                        
                                    }
                                    else if(param.formatJsName == "banner_atf_bottom_over"){
                                        if(isTouchDevice()){
                                            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/320x100.jpg"></div>';
                                        }
                                        else{
                                            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/970x250.jpg"></div>';
                                        }
                                    }
                                    else{
                                        tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/logo-dev2pub.png" style="width: 120px;height:60px;"></div>';
                                    }
                                    
                                    launchFormat(divId, param.demoHeight, param.demoWidth, param.formatJsName, param.demoMode, false);
                                    launchNextTags();
                                }
                                else{
                                    //TODOVIDEO
                                    if(param.formatType != "VIDEO" && param.videoContext != "INSTREAM"){
                                        let adDiv = document.createElement("div");
                                        adDiv.id = divId;

                                        tag.parentNode.insertBefore(adDiv, tag.nextSibling);
                                    }
                                    else{
                                        let adDiv = document.createElement("video");
                                        adDiv.id = divId;

                                        tag.parentNode.insertBefore(adDiv, tag.nextSibling);
                                    }

                                    adUnits = addAdUnits(param, divId, adUnits);
                                    param.divId = divId;
                                    paramsLaunched.push(param);
                                }
                            }
                        }
                    }
                }

                if(adUnits.length > 0){
                    d2ppbjs.addAdUnits(adUnits);

                    //Run auction
                    requestBids(paramsLaunched,adUnits,true);
                    if(paramsToLaunch[0].tagRefreshDelaySeconds && paramsToLaunch[0].tagRefreshDelaySeconds > 0 ){
                        var dev2pubRefreshInterval = setInterval(function(){requestBids(paramsLaunched,adUnits,false,dev2pubRefreshInterval);},paramsToLaunch[0].tagRefreshDelaySeconds * 1000);
                    }
                }
            }
        }

        function addAdUnits(param, divId, adUnits){
            if(isConnectedTV() == false){
                //Add adUnit
                let bids = param.theMoneytizerAdUnit.bids;

                let div;
                if (inIFrame()) {
                    div = parent.document.getElementById(divId);
                }
                else {
                    div = document.getElementById(divId);
                }

                let isInViewPort = isInViewport(div);

                bids.forEach((bid, bidIndex) => {
                    if(bid.bidder == "adagio"){
                        bid.params.adUnitElementId = divId;

                        if(param.formatType != "VIDEO"){
                            bid.params.placement = param.name;
                        }
                        else{
                            bid.params.placement = "outstream";
                        }
                        
                        if(param.isMobile){
                            bid.params.environment = "mobile";
                        }
                        else{
                            bid.params.environment = "desktop";
                        }
                        
                        bid.params.category = param.category;
                    }
                    else if(bid.bidder == "rubicon" ){
                        if(isInViewPort){
                            bid.params.position = "atf";
                        }
                        else{
                            bid.params.position = "btf";
                        }

                        bid.params.keywords = [param.category];

                        if(param.name == "Habillage"){
                            bid.params.renderer = {
                                url: 'https://secure-assets.rubiconproject.com/utils/prebidSkin/prebidSkin.js',
                                render: function(prebidBid) {
                                    this.push(() => {
                                        window.skinOverlay.renderAd({
                                            fullBids: prebidBid,
                                            insertionMarker: "body",                    
                                            contentWidthMarker: "#11d88c1e-d428-43e7-92e0-4deafee86b16",
                                            insertionType: "ratio",
                    
                                        });
                                    })
                                }
                            }
                        }
                    }
                    else if(bid.bidder == "appnexus"){
                        bid.params.allowSmallerSizes = true;
                    }
                    else if(bid.bidder == "richaudience"){
                        bid.params.supplyType = "site";
                    }
                    else if(bid.bidder == "adform"){
                        bid.params.cdims = param.theMoneytizerAdUnit.sizes;
                    }

                    param.theMoneytizerAdUnit.bids[bidIndex] = bid;
                });

                let mediaTypes;
                if(param.formatType == "VIDEO"){
                    mediaTypes = {
                        video: {
                            ////////startdelay : 0,
                            context: param.videoContext,
                            ////playerSize: param.theMoneytizerAdUnit.sizes,
                            playerSize: [640,480],
                            mimes: ['video/mp4', 'video/webm', 'video/x-ms-wmv','video/flv','application/javascript', 'video/ogg'],
                            ////protocols: [3,6,7,8],
                            //////protocols: [2,3,5,6],
                            ////////protocols: [1,2,3,4,5,6,7,8,9,10,11,12,13,14],
                            protocols: [5,6,8],
                            placement: 1,
                            ////api: [1,2,3,4,5],
                            //////api: [1,2],
                            api: [1,2,3,4,5,6],
                            minduration: 0,
                            maxduration: 31,
                            linearity: 1,
                            playerWidth: "640",
                            playerHeight: "480"
                            //playbackmethod: 1,
                            ////linearity: 1,
                            //minduration: 6,
                            //maxduration: 30,
                            //startdelay: 0,
                            //placement: 1, //1 pour in-stream
                            //playbackmethod: [1,2,3,4,5,6]
                        }
                    }
                }
                else{
                    mediaTypes = {
                        banner: {
                            sizes: param.theMoneytizerAdUnit.sizes
                        }
                    }
                }

                let adUnit = {
                    code: param.theMoneytizerAdUnit.code,
                    mediaTypes: mediaTypes,
                    /*renderer: {
                        url: "https://acdn.adnxs.com/video/outstream/ANOutstreamVideo.js",
                        render: function (bid) {
                          var adResponse = {
                            ad: {
                              video: {
                                content: bid.ad,
                                player_height: bid.height,
                                player_width: bid.width,
                              },
                            },
                          };
                          bid.renderer.push(function() {
                            ANOutstreamVideo.renderAd({
                              targetId: bid.adUnitCode, // target div id to render video.
                              adResponse: adResponse,
                            });
                          });
                        },
                      },*/
                    bids: param.theMoneytizerAdUnit.bids,
                    divId : divId,
                    idSiteFormat: param.id,
                    sizes: param.theMoneytizerAdUnit.sizes,
                    floors: {
                        currency: 'USD',
                        //skipRate: 5,
                        modelVersion: 'Ad Unit Floors',
                        schema: {
                            fields: [ 'mediaType']
                        },
                        values: {
                            'banner': param.bidFloor,
                            'video': param.bidFloor
                        },
                        default: 0.01
                    },
                    ortb2Imp: {
                        ext:{
                            data:{
                                divId: divId,
                                placement:param.name
                            }
                        }
                    }
                };

                adUnits.push(adUnit);
            }
            
            return adUnits;
        }

        function setConfig(params){
            d2ppbjs.aliasBidder('appnexus', 'goodad');

            d2ppbjs.bidderSettings = {
                onetag: {
                    bidCpmAdjustment : function(bidCpm){
                        bidCpm = bidCpm * 0.9;

                        return bidCpm;
                    },
                    storageAllowed: true 
                },
                oftmedia: {
                    bidCpmAdjustment : function(bidCpm){
                        //Adjustment
                        return bidCpm * .8;
                    },
                },
                blueroostermedia: {
                    bidCpmAdjustment : function(bidCpm){
                        return bidCpm * .7;
                    },
                },
                rubicon: {
                    bidCpmAdjustment : function(bidCpm){
                        //Adjustment
                        return bidCpm * .9;
                    },
                },
                adform: {
                    bidCpmAdjustment : function(bidCpm){
                        return bidCpm * .73;
                    },
                },
                criteo: {
                    bidCpmAdjustment : function(bidCpm){
                        return bidCpm * 1.13;
                    },
                    storageAllowed: true 
                },
                improvedigital: {
                    bidCpmAdjustment : function(bidCpm){
                        //Adjustment
                        return bidCpm * .8;
                    },
                },
                smartadserver: {
                    bidCpmAdjustment : function(bidCpm){
                        return bidCpm * .6;
                    },
                },
                outbrain: {
                    bidCpmAdjustment : function(bidCpm){
                        return bidCpm * 0.8;
                    },
                    storageAllowed: true
                },
                missena: {
                    bidCpmAdjustment: function(bidCpm, bid){ 
                        return bidCpm * 0.7;
                    },
                    storageAllowed: true
                },
                adagio: {
                    storageAllowed: true
                },
                gumgum: {
                    storageAllowed: true
                },
                ix: { 
                    storageAllowed: true 
                },
                eplanning: { 
                    storageAllowed: true 
                },
                appnexus: { 
                    bidCpmAdjustment: function(bidCpm, bid){ 
                        return bidCpm * 0.75;
                    },
                    storageAllowed: true 
                },
                openx: { 
                    bidCpmAdjustment: function(bidCpm, bid){ 
                        return bidCpm * 0.75;
                    },
                    storageAllowed: true 
                },
                amx: { 
                    storageAllowed: true 
                }   
            };

            let config = {
                "schain": {
                    "validation": "relaxed",
                    "config": {
                    "ver":"1.0",
                    "complete": 1,
                    "nodes": 
                        [
                            {
                                "asi":"themoneytizer.com",
                                "sid":"74458",
                                "hp":1
                            },
                            {
                                "asi":"152media.info",
                                "sid":"152M960",
                                "hp":1
                            }
                        ]
                    }
                },
                improvedigital: {
                    usePrebidSizes: true
                },
                outbrain: {
                    bidderUrl: 'https://b1h.zemanta.com/api/bidder/prebid/bid/',
                    usersyncUrl: 'https://b1h.zemanta.com/usersync/prebid'
                },
                deviceAccess: true,
                userSync: {
                    filterSettings: {
                        iframe: {
                            bidders: '*',
                            filter: 'include'
                         },
                         image: {
                            bidders: '*',
                            filter: 'include'
                         }
                    },
                    aliasSyncEnabled:true,
                    auctionDelay: 30,
                    syncsPerBidder: 8,
                    syncDelay: 3000,
                    userIds: [{
                        name: "criteo"
                    },
                    {
                        name: "pubProvided"
                    },
                    {
                        name: 'uid2'
                    },
                    {
                        name: 'pubCommonId',
                        bidders: [
                            'between'
                        ],
                        params: {
                            syncTime: 60 // in seconds, default is 24 hours
                        },
                        storage: {
                            name: 'pubCommonId',
                            type: 'cookie',
                            expires: 28
                        },
                    },
                    {
                        "name": "quantcastId"
                    },
                    {
                        name: "sharedId",
                        storage: {
                            type: "cookie",
                            name: "_sharedid",         // create a cookie with this name
                            expires: 365             // expires in 1 years
                        }
                    },
                    {
                        name: "id5Id",
                        params: {
                        partner: 12
                        },
                        storage: {
                        type: "html5",
                        name: "id5id",
                        expires: 45
                        }
                    },
                    {
                        name: "amxId",
                        storage: {
                            type: "html5",
                            name: "amxId",
                            expires: 1
                        }
                    }
                ]
                },
                priceGranularity: "dense",
                glvMapping: {
                    goodad: 32
                },
                floors: {},
                currency: {
                    adServerCurrency: "USD"
                 },
                 enableSendAllBids: false,
                 timeoutBuffer: 400,
                 disableAjaxTimeout: true,
                 maxRequestsPerOrigin: 1,
                 enableTIDs: true,
                 allowActivities: {
                    fetchBids: {
                        rules: [{allow: true}]
                    },
                    accessDevice: {
                        rules: [{allow: true}]
                    },
                    syncUser: {
                        rules: [{allow: true}]
                    },
                    transmitEids: {
                        rules: [{allow: true}]
                    },
                    enrichEids: {
                        rules: [{allow: true}]
                    }
                  },
                  ortb2:{
                    site:{
                        ext:{
                            data:{
                                pagetype:"",
                                category:""
                            }
                        }
                      }
                  }
            };
            
            if(rgpdActive()){
                config.consentManagement = {
                    gdpr: {
                        cmpApi: 'iab',
                        allowAuctionWithoutConsent: true, // suppress auctions if there's no GDPR consent string
                        defaultGdprScope: false,
                        timeout: 3000,  // GDPR timeout 3000ms
                        allowAuctionWithoutConsent: true,
                        rules: [{
                            vendorExceptions: ["between","adtelligent","smartyads","goodad","eplanning","rise","blueroostermedia","adpone"],
                            enforcePurpose:false,
                            enforceVendor:false
                          }
                        ]
                    }
                }
            };

            if(uspActive()){
                config.consentManagement.usp = {
                    cmpApi: 'iab',
                    timeout: 50 // US Privacy timeout 100ms
                }
            };


            /*config.debugging = {
                enabled: true,
                intercept: [
                  {
                    when: {
                      // intercept bids from bidderA that have adUnitCode === 'test-div'
                      adUnitCode: '11d88c1e-d428-43e7-92e0-4deafee86b16',
                      bidder: 'smartyads'
                    },
                    then: {
                        cpm: 10,
                        mediaType: "video",
                        source: "client",
                        currency: "EUR",
                        cpm: 50.00,
                        creativeId: "11111",
                        width: 640,
                        height: 480,
                        vastUrl: "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
                    }
                  },
                ]
            };*/

            
            for (let param of params){
                let exitLoop = true;
                for (let bid of param.theMoneytizerAdUnit.bids){
                    if(bid.bidder == "adagio"){
                        config.realTimeData = {
                            dataProviders: [
                              {
                                name: "adagio",
                                params: {
                                  organizationId: bid.params.organizationId,
                                  site: bid.params.site
                                },
                              },
                            ],
                        };
                        exitLoop = true;
                        break;
                    }
                }
                if(exitLoop == true){
                    break;
                }
            }

            d2ppbjs.que.push(function(){
                d2ppbjs.setConfig(config);
                d2ppbjs.enableAnalytics({
                    provider: 'smartyads'
                });
            });
        }

        function getTags(){
            //Get dev2pub.js tags
            let allJsScript = document.getElementsByTagName('script');
    
            let tags = [];
            
            for (var i = 0; i < allJsScript.length; ++i) {
                let src = allJsScript[i].getAttribute("src");
                if(src){
                    if(src.includes("dev2pub.js")){
                        allJsScript[i].launched = false;
                        tags.push(allJsScript[i]);
                    }
                }
            }

            return tags;
        }

        /**
         * Load Dev2Pub Prebid library that build in our own Prebid project
         * TODO See if we load Prebid in parent page when the script is call in a iframe
         */
        function loadPrebid(){
            let pbjsScript = document.createElement("script"); 
            pbjsScript.type = "text/javascript";
            pbjsScript.defer = true; 
            pbjsScript.id = "d2pPrebid";
            pbjsScript.src = DEV2PUB_JS_SCRIPT_URL + "/prebid.js";
     
            let pbjsTargetEl = document.getElementsByTagName("head")[0];
            pbjsTargetEl.insertBefore(pbjsScript, pbjsTargetEl.firstChild);
            
            return pbjsScript;
        }


        function getTagId(tag){
            let dev2pub_queryString = tag.src.replace(/^[^\?]+\??/, '');
            let dev2pub_params = parseQuery(dev2pub_queryString);
    
            if(dev2pub_params.id){
                let id = dev2pub_params.id.replace("'", "").replace("'", "").replace('"', "").replace('"', "");
                return id;
            }
            else{
                return "";
            }
        }

        function loadTheMoneytizerTag(param){
            const scriptEl = document.createRange().createContextualFragment(param.theMoneytizerTag);
    
            if(param.idTheMoneytizerFormatName == "16"){
                //RECO CONTENU
                let recoDiv = document.createElement("div");
                recoDiv.style.width = "50%";
                recoDiv.style.marginRight = "auto";
                recoDiv.style.marginLeft = "auto";
                recoDiv.appendChild(scriptEl);
                document.getElementsByTagName('body')[0].appendChild(recoDiv);
            }
            else{
                document.getElementsByTagName('head')[0].appendChild(scriptEl);
            }

            launchNextTags();
        }

        function loadDirectTag(param){
            let tag = document.getElementById(param.id + "script");
            const scriptEl = document.createRange().createContextualFragment(param.directTag);
            tag.parentNode.insertBefore(scriptEl, tag.nextSibling);
        }

        function launchFormat(divId, height, width, formatJsName, demoMode, refresh, vastURL){
            switch (formatJsName) {
                case "fixed_bloc":
                    fixed_bloc(divId, width, height, refresh, demoMode);
                    break;
                case "banner_atf_bottom_over_fixed":
                    banner_atf_bottom_over_fixed(divId, width, height, refresh, demoMode);
                    break;
                case "banner_atf_bottom_over":
                    banner_atf_bottom_over(divId, width, height, refresh, demoMode);
                    break;
                case "banner_atf_bottom_over_on_scroll_hide":
                    banner_atf_bottom_over_on_scroll_hide(divId, width, height, refresh, demoMode);
                    break;
                case "banner_atf_bottom_over_on_scroll_show":
                    banner_atf_bottom_over_on_scroll_show(divId, width, height, refresh, demoMode);
                    break;
                case "banner_atf_top_over":
                    banner_atf_top_over(divId, width, height, refresh, demoMode);
                    break; 
                case "banner_atf_top_push":
                    banner_atf_top_push(divId, width, height, refresh, demoMode);
                    break; 
                case "passback_atf_side_left_over_fixed_on_scroll":
                    passback_atf_side_left_over_fixed_on_scroll(divId, width, height, refresh, demoMode);
                    break; 
                case "passback_atf_side_right_over_fixed_on_scroll":
                    passback_atf_side_right_over_fixed_on_scroll(divId, width, height, refresh, demoMode);
                    break; 
                case "passback_atf_side_left_over_on_scroll_show":
                    passback_atf_side_left_over_on_scroll_show(divId, width, height, refresh, demoMode);
                    break;
                case "passback_atf_side_right_over_on_scroll_show":
                    passback_atf_side_right_over_on_scroll_show(divId, width, height, refresh, demoMode);
                    break;
                case "corner_display":
                    corner_display(divId, width, height, demoMode);
                case "instream_video_tamildhool":
                    instream_video_tamildhool(divId, width, height, demoMode,vastURL);
            }
        }


        function requestBids(params,adUnits,firstLaunch,dev2pubRefreshInterval){
            setConfig(params);

            if(d2ppbjs.adUnits.length > 0){
                console.log("Request bids");
            
                d2ppbjs.que.push(function(){
                    d2ppbjs.requestBids({
                        bidsBackHandler: function(bidResponses){
                            if(firstLaunch){
                                sendResponsesTime(params);
                            }
                            prebidBidsBackHandler(params, bidResponses,firstLaunch);
                        },
                        timeout: 3000
                    });
                });
            }  
        }

        function sendResponsesTime(params){
            let responsesTime = [];
            let adUnits = d2ppbjs.getNoBids();
            
            for(let adunitCode in adUnits){
                adUnits[adunitCode]["bids"].forEach(bid => {
                    let responseTime = {
                        bidderCode: bid.bidder,
                        delay:bid["metrics"].getMetrics()["adapter.client.total"],
                        idSite : params[0].idSite
                    }

                    let responseTimeExist = false;
                    for(let responseTimeKey in responsesTime){
                        if(responsesTime[responseTimeKey].bidderCode == bid.bidder)
                        {
                            responseTimeExist = true;
                        }
                    }
                    if(responseTimeExist == false){
                        responsesTime.push(responseTime);
                    }
                });
            }

            if (responsesTime.length > 0) {
                let dev2pub_sendResponsesTimeXmlhttp = new XMLHttpRequest();
    
                dev2pub_sendResponsesTimeXmlhttp.onreadystatechange = function () {
                    if (dev2pub_sendResponsesTimeXmlhttp.readyState == XMLHttpRequest.DONE) {
                        console.log("Response time save");
                    }
                    else if (dev2pub_sendResponsesTimeXmlhttp.status == 400) {
                        console.log('There was an error 400');
                        console.log(dev2pub_sendResponsesTimeXmlhttp.responseText);
                    }
                    else {
                        if (dev2pub_sendResponsesTimeXmlhttp.status != 200 && dev2pub_sendResponsesTimeXmlhttp.status != 201 && dev2pub_sendResponsesTimeXmlhttp.status != 0) {
                            console.log('something else other than 200 was returned');
                            console.log(dev2pub_sendResponsesTimeXmlhttp.responseText);
                            console.log(dev2pub_sendResponsesTimeXmlhttp.status);
                        }
                    }
                }
    
                dev2pub_sendResponsesTimeXmlhttp.open("POST", DEV2PUB_API_URL + "/api/public/Dev2Pub/SendResponseTime/", true);
                dev2pub_sendResponsesTimeXmlhttp.setRequestHeader('Content-type', 'application/json');
                dev2pub_sendResponsesTimeXmlhttp.send(JSON.stringify(responsesTime));
            }
        }

        function prebidBidsBackHandler(params,bidResponses, firstLaunch){
            
            if(bidResponses){
                for(let param of params){
                    
                    let ad = d2ppbjs.getHighestCpmBids(param.theMoneytizerAdUnit.code);
                    if(param.formatType == "VIDEO"){
                        console.log("getHighestCpmBids (ad)");
                        console.log(ad[0]);
                    }

                    let vastURL = "";

                    if(ad && ad.length > 0){
                        var sizeOk = false;
                        param.theMoneytizerAdUnit.sizes.forEach(size => {
                            if(ad[0].width == size[0] && ad[0].height == size[1]){
                                sizeOk = true;
                            }
                        });

                        if(sizeOk == true){
                            if(param.formatType != "VIDEO"){
                                let divId = param.id;
                    
                                if (inIFrame()) {
                                    if (!window.frameElement.id) {
                                        window.frameElement.id = params.id;
                                    }
                                    divId = window.frameElement.id;
                                }
    
                                let idIFrame = divId + "frame";
    
                                let iFrame = "<iframe id='" + idIFrame + "'"
                                + " FRAMEBORDER=\"0\""
                                + " SCROLLING=\"no\""
                                + " MARGINHEIGHT=\"0\""
                                + " MARGINWIDTH=\"0\""
                                + " TOPMARGIN=\"0\""
                                + " LEFTMARGIN=\"0\""
                                + " ALLOWTRANSPARENCY=\"true\""
                                + " WIDTH=\"0\""
                                + " HEIGHT=\"0\">."
                                + " </iframe>";
    
                                let adDiv = document.getElementById(param.divId);
                                //adDiv.id = divId;
                                adDiv.innerHTML = iFrame; 
    
                                var iframe = document.getElementById(idIFrame);
    
                                var iframeDoc = iframe.contentWindow.document;
    
                                iframeDoc.open('text/html', 'replace');
                                iframeDoc.close();
                                
                                try { //TODOVIDEO faire uniquement pour display, voir https://docs.prebid.org/examples/video/outstream/pb-ve-outstream-no-server-specify-renderer.html
                                    d2ppbjs.renderAd(iframeDoc, ad[0]['adId']);
                        
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                            else{
                                //TODOVIDEO faire traitement affichage vidéo
                                var videoDiv = document.getElementById(param.id);
                                console.log("ADS");
                                console.log(ad[0]);
                                d2ppbjs.renderAd(videoDiv, ad[0]['adId']);

                                /*d2ppbjs.markWinningBidAsUsed({
                                    adUnitCode: param.adUnitCode, // optional if you know the adId
                                    adId: ad[0].adId // optional
                                });
    
                                vastURL = ad[0].vastUrl;*/
                            }
    
                            console.log("Bid success");
    
                            let winningBids = d2ppbjs.getAllWinningBids();
                            if(param.formatType == "VIDEO"){
                                console.log("winningBids");
                                console.log(winningBids);
                            }
    
                            if(winningBids){
                                for (let winningBid of winningBids) {
                                    //Display animation
                                    if (winningBid.adId == ad[0].adId) {
                                        
                                        if(windowTop.animationLoad[param.id] == null || windowTop.animationLoad[param.id] == false){
                                            launchFormat(param.divId, ad[0].height, ad[0].width, param.formatJsName, false, false, vastURL);
                                            windowTop.animationLoad[param.id] = true;
                                            
                                        }   
                                        else{
                                            console.log("Animation already load");
                                            
                                            if(param.formatType != "VIDEO"){
                                                launchFormat(param.divId, ad[0].height, ad[0].width, param.formatJsName, false, true, vastURL);
                                            }
                                        }
                                    }
                                }
                                
                                sendSuccess(ad[0]);
                            }
                        }
                        else{
                            console.log("Ads sizes are not in adunit");
                            if(firstLaunch){
                                sendFailed(param);
                                let tag = document.getElementById(param.id + "script");
                                if(tag){
                                    if(!param.dev2pubPassbackRun){
                                        runPassback(param, tag, param.divId);
                                    }
                                }
                                
                            }
                        }
                    }
                    else{
                        console.log("No bids");
                        if(firstLaunch){
                            sendFailed(param);
                            let tag = document.getElementById(param.id + "script");
                            if(tag){
                                if(!param.dev2pubPassbackRun){
                                    runPassback(param, tag, param.divId);
                                }
                            }
                        }
                    }
                }

                d2ppbjs.resetAuctions();
            }
            

            //launchNextTags();
        }

        function sendSuccess(ad){
            if(ad){
                //Send bid to dev2pub server
                let displayAds = [];
        
                let idSiteFormat = "";
                if (ad.params && ad.params[0] && ad.params[0].idSiteFormat) {
                    idSiteFormat = ad.params[0].idSiteFormat;
                }
                else {
                    idSiteFormat = ad.adUnitCode
                }

                displayAds.push(
                    {
                        "IdSiteFormat": idSiteFormat,
                        "BidderCode": ad.bidderCode,
                        "Cpm": ad.cpm,
                        "Currency": ad.currency,
                        "Width": ad.width,
                        "Height": ad.height,
                        "MediaType": ad.mediaType,
                        "NetRevenue": ad.netRevenue,
                        "Size": ad.size,
                        "StatusMessage": ad.statusMessage,
                        //"Status": ad.status,
                        //"Params": JSON.stringify(ad.params),
                        //"AdId": ad.adId,
                        //"AuctionId": ad.auctionId,
                        "AdUnitCode": ad.adUnitCode,
                        "BidFloor": ad.bidFloor,
                        "IsRefresh": isRefresh
                    }
                );

                if (displayAds.length > 0) {
                    let dev2pub_sendBidXmlhttp = new XMLHttpRequest();
        
                    dev2pub_sendBidXmlhttp.onreadystatechange = function () {
                        if (dev2pub_sendBidXmlhttp.readyState == XMLHttpRequest.DONE) {
                            console.log("Bid save");
                        }
                        else if (dev2pub_sendBidXmlhttp.status == 400) {
                            console.log('There was an error 400');
                            console.log(dev2pub_sendBidXmlhttp.responseText);
                        }
                        else {
                            if (dev2pub_sendBidXmlhttp.status != 200 && dev2pub_sendBidXmlhttp.status != 201 && dev2pub_sendBidXmlhttp.status != 0) {
                                console.log('something else other than 200 was returned');
                                console.log(dev2pub_sendBidXmlhttp.responseText);
                                console.log(dev2pub_sendBidXmlhttp.status);
                            }
                        }
                    }
        
                    dev2pub_sendBidXmlhttp.open("POST", DEV2PUB_API_URL + "/api/public/Dev2Pub/SendAdDisplay/", true);
                    dev2pub_sendBidXmlhttp.setRequestHeader('Content-type', 'application/json');
                    dev2pub_sendBidXmlhttp.send(JSON.stringify(displayAds));

                    isRefresh = true;
                }
            }
        }

        function sendFailed(params){
            let noBids = [];
        
            noBids.push({
                "IdSiteFormat": params.id,
                "BidFloor": params.bidFloor,
                "Failed": true
            });
        
            let sendFailedRequest = new XMLHttpRequest();
            sendFailedRequest.onreadystatechange = function(){
                if (sendFailedRequest.readyState == XMLHttpRequest.DONE) {
                    
                    //console.log(dev2pub_sendBidXmlhttp.responseText);
                }
                else if (sendFailedRequest.status == 400) {
                    console.log('There was an error 400');
                    console.log(sendFailedRequest.responseText);
                }
                else {
                    if (sendFailedRequest.status != 200 && sendFailedRequest.status != 201 && sendFailedRequest.status != 0) {
                        console.log('something else other than 200 was returned');
                        console.log(sendFailedRequest.responseText);
                        console.log(sendFailedRequest.status);
                    }
                }
            }
        
            //Send the proper header information along with the request
            sendFailedRequest.open("POST", DEV2PUB_API_URL + "/api/public/Dev2Pub/SendAdDisplay/", true);
            sendFailedRequest.setRequestHeader('Content-type', 'application/json');
            sendFailedRequest.send(JSON.stringify(noBids));
        
            console.log("Bid failed save");
        }

        function runPassback(param, tag, divId){
            if (tag) {
                if (param.passback) {
                    console.log("Run passback");
                    if(inIFrame()){
                        /*const scriptEl = parent.document.createRange().createContextualFragment(params.passback);
                        let divToAppend = parent.document.getElementById(divId);
                        divToAppend.parentNode.insertBefore(scriptEl, divToAppend.nextSibling);*/
                        
                        /*const scriptEl = document.createRange().createContextualFragment(params.passback);
                        tag.parentNode.append(scriptEl);*/

                        const scriptEl = document.createRange().createContextualFragment(param.passback);
                        tag.parentNode.insertBefore(scriptEl, tag.nextSibling);
                    }
                    else{
                        const scriptEl = document.createRange().createContextualFragment(param.passback);
                        tag.parentNode.insertBefore(scriptEl, tag.nextSibling);
                    }
                    
                    //d2ppbjs.removeAdUnit(param.theMoneytizerAdUnit.code);
                    param.dev2pubPassbackRun = true;
                    //dev2pubPassbackRun = true;
                    
                }
                else{
                    //if not passback, we re launch every minutes
                    /*console.log("Passback");
                    setInterval(function(){requestBids(params, tag, divId, false);},60000);*/
                }
            }
        }
    
})(window);
